import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  AuthenticationProvider,
  oidcLog,
  InMemoryWebStorage,
  useReactOidc,
  getUserManager,
  OidcSecure
} from '@axa-fr/react-oidc-context';
import { oidcConfig } from './utils/config';
import AdminLayout from './layouts/AdminLayout';
import Oidc from './screens/Oidc';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader } from './api/api';
import MissingLicense from './screens/Errors/MissingLicense';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useFeature } from './hooks/useFeature';
import { Toaster } from './toaster';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import React from 'react';
import Dashboard from './screens/Dashboard';
import DocumentationAPI from './screens/DocumentationAPI';

function App() {
  return (
    <div className="app min-h-screen">
      <Router>
        <AuthenticationProvider
          configuration={oidcConfig}
          loggerLevel={oidcLog.ERROR}
          isEnabled={true}
          UserStore={InMemoryWebStorage}
          callbackComponentOverride={Oidc}
          notAuthenticated={() => {
            return <h1>Not Authenticated.</h1>;
          }}
          notAuthorized={() => {
            return <h1>Not Authorized.</h1>;
          }}
          authenticating={Oidc}
        >
          <Switch>
            <Route exact={true} path="/unauthorized" component={Unauthorized} />
            <Route exact={true} path="/missing-license" component={MissingLicense} />
            <Route exact={true} path="/not-found" component={NotFound} />

            <Route exact={true} path="/documentation-api" component={DocumentationAPI} />
            <Route exact={true} path="/" component={Dashboard} />
            {/* <OidcSecure>
              <SetAuthToken>
                <SetOidcRoutesContext>
                  <AdminLayoutRoute
                    exact={true}
                    path="/"
                    nav="dashboard"
                    component={Dashboard}
                    featureEnabled={true}
                  />
                </SetOidcRoutesContext>
              </SetAuthToken>
            </OidcSecure> */}
          </Switch>
        </AuthenticationProvider>
      </Router>
      <Toaster />
    </div>
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  requiredFeatureCode,
  ...rest
}) {
  const feature = useFeature();

  return (
    <ProtectedRoute
      featureEnabled={featureEnabled}
      hasFeature={feature.hasFeature(requiredFeatureCode)}
    >
      <Route
        {...rest}
        render={(props) => (
          <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
            <Component {...props} />
          </AdminLayout>
        )}
      />
    </ProtectedRoute>
  );
}

const ProtectedRoute = ({ featureEnabled, hasFeature, children }) => {
  return featureEnabled && hasFeature ? <>{children}</> : <Redirect to={'/'} />;
};

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
    }, 'Failed to load user data');
  };

  useEffect(() => {
    loadUserContextData();
  }, []);

  return (
    !loadAction.isExecuting &&
    userProfile &&
    customerInfo && (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    )
  );
};

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  return <>{children}</>;
};

export default App;
