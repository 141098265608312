import React, { useEffect, useState } from 'react';
import { getDashboard } from '../../api/dashboard';

const PathNameMappToRoute = {
  ApprovalsPending: '/admin/approvals?status=pending',
  ApprovalsActive: '/admin/approvals?status=active'
};

const Dashboard = () => {

  const [data, setData] = useState([])
  const load = async () => {
    const data = await getDashboard();
    setData(data);
  }
useEffect(() => {
  load();
}, [])


  return (
    <div className="w-full h-full">
      <h1 className="text-3xl font-bold">Dashboard</h1> 
      {
        data.map (x=> 
          <div className="">
            {x.name}
          </div>
        )
      }
    </div>
  );
};

export default Dashboard;
